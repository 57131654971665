// @flow
import React from 'react';
import Markdown from 'react-markdown';

import styles from './styles.module.scss';

type Props = {
  item: {
    image: string,
    title: string,
    content: string
  }
}

const ListItem = ({ item }: Props) => (
  item ? (
    <div className={styles.listItem}>
      <div className={styles.image}>
          <img src={item.image} loading="lazy"/>
      </div>
      <div className={styles.text}>
          <h3>{item.title || '#'}</h3>
          <Markdown>{item.content}</Markdown>
      </div>
    </div>
  ) : null
)

export default ListItem
